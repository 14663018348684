.payment-popup {
    height: 560px;
}

.payment-wrapper {
    display: flex;
    flex-direction: column;
}

.payment-wrapper .subtitle {
    text-align: center;
}

@media screen and (max-width: 768px) {
    .hide-on-mobile {
        visibility: hidden;
        display: none !important;
    }
}

.auto-product__container .swiper-slide {
    width: 76% !important;
}
.auto-product__container .main-popular__text {
    height: 100px;
}