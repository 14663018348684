.leaders__title {
    font-size: 24px;
    margin-top: 40px;
    margin-bottom: 40px;
    padding-left: 24px;
}

.leaders__list {
    margin-bottom: 36px;
}

.leaders__item {
    margin-bottom: 24px;
}

.leaders__item-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 24px;
}

.leaders__item-text {
    font-size: 16px;
}

.leaders__item-job {
    font-weight: 700;
    margin-bottom: 16px;
}

.leaders__item-name {
    font-size: 18px;
}

.leaders__item-img {
    width: 350px;
    height: 400px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border: 1px solid #fbbe07;
    border-radius: 8px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

.director {
    background-image: url(./img/leaders/director.jpg);
}

.man {
    background-image: url(./img/leaders/man.jpg);
}

.woman {
    background-image: url(./img/leaders/woman.jpg);
}



@media (max-width: 959px) {
    .leaders__title {
        font-size: 20px;
    }
    .leaders__item-img {
        width: 280px;
        height: 320px;
    }
    .leaders__item-job {
        font-size: 14px;
    }
    .leaders__item-name {
        font-size: 16px;
    }
}
@media (max-width: 640px) {
    .leaders__title {
        font-size: 18px;
    }
    .leaders__item-wrapper {
        flex-direction: column;
        gap: 16px;
    }
    .leaders__item {
        margin-bottom: 46px;
    }
    .leaders__item-job {
        margin-bottom: 6px;
    }
}